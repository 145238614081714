<template>
  <div class="article_list_box">
    <div class="ad">
      <img style="width: 100%; height: 100%" src="../../assets/img/yjzx.png" />
    </div>
    <div class="width_1200 second_level_study">
      <div class="second_level_study_top">
        <div class="second_level_study_top_left">
          <p class="second_level_study_top_title">
            <span>研究报告</span>
            <span
              style="font-size: 14px; color: #666666; cursor: pointer"
              @click="handle_more('研究报告')"
              >更多>></span
            >
          </p>
          <div
            class="column_name_list"
            :style="{ height: tabList1.length > 0 ? '40px' : '0px' }"
          >
            <div
              class="column_name_item"
              :class="item.columnId == cid ? 'current' : ''"
              v-for="item in tabList1"
              :key="item"
              @click="handle_tab(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="second_level_study_top_left_list">
            <div
              class="second_level_study_top_left_item"
              v-for="item in list1"
              :key="item.id"
            >
              <p @click="handle_(item, '1_')">
                <span class="dian"></span> {{ item.title }}
              </p>
              <span>{{ item.publishTime }}</span>
            </div>
          </div>
        </div>
        <div class="second_level_study_top_right">
          <p class="second_level_study_top_title">
            <span>投资策略</span>
            <span
              style="font-size: 14px; color: #666666; cursor: pointer"
              @click="handle_more('投资策略')"
              >更多>></span
            >
          </p>
          <div
            class="column_name_list"
            :style="{ height: tabList2.length > 0 ? '40px' : '0px' }"
          >
            <div
              class="column_name_item"
              :class="item.columnId == cid1 ? 'current' : ''"
              v-for="item in tabList2"
              :key="item"
              @click="handle_tab1(item)"
            >
              {{ item.name }}
            </div>
          </div>
          <div class="second_level_study_top_left_list">
            <div
              class="second_level_study_top_left_item"
              v-for="item in list2"
              :key="item.id"
            >
              <p @click="handle_(item, '2_')">
                <span class="dian"></span>{{ item.title }}
              </p>
              <span>{{ item.publishTime }}</span>
            </div>
          </div>
        </div>
      </div>
      <div class="investor_protection_bottom">
        <p class="investor_protection_item">
          <span class="investor_protection_title_left">恒银直播</span>
          <span class="more" @click="goMore">更多>></span>
        </p>
        <div class="insured_work_list">
          <div
            class="insured_work_item"
            v-for="item in list3"
            :key="item.id"
            @click="handle_zhibo(item)"
          >
            <div class="thumb">
              <img :src="http + item.cover" />
            </div>
            <p>{{ item.title }}</p>
          </div>
        </div>
      </div>
      <div class="investor_protection_bottom">
        <p class="investor_protection_item">
          <span class="investor_protection_title_left">数据中心</span>
        </p>
        <div class="data_center">
          <div
            :class="
              idx != 1 ? 'data_center_item' : 'data_center_item highlight'
            "
            @click="handle_highlight(1)"
          >
            持仓数据
          </div>
          <div
            :class="
              idx != 2 ? 'data_center_item' : 'data_center_item highlight'
            "
            @click="handle_highlight(2)"
          >
            品种资讯
          </div>
          <div
            :class="
              idx != 3 ? 'data_center_item' : 'data_center_item highlight'
            "
            @click="handle_highlight(3)"
          >
            商品指数
          </div>
        </div>
        <div class="data_center_compontent">
          <holdaposition
            :is_study_information="1"
            v-if="idx == 1"
          ></holdaposition>
          <investmentresearch
            :is_study_information="1"
            v-if="idx == 2"
          ></investmentresearch>
          <commodityindex
            :is_study_information="1"
            v-if="idx == 3"
          ></commodityindex>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import investmentresearch from '@/views/study_information/Investment_research.vue';
import commodityindex from '@/views/study_information/commodity_index.vue';
import holdaposition from '@/views/study_information/hold_a_position.vue';
export default {
  components: {
    holdaposition,
    investmentresearch,
    commodityindex,
  },
  data() {
    return {
      cid: '1457026263679008',
      cid1: '1460477672554528',
      id: '1457026454519840',
      id1: '1469169721671714',
      name: '黑色系',
      name1: '短线策略',
      list1: [],
      list2: [],
      list3: [],
      tabList1: [],
      tabList2: [],
      idx: 1
    }
  },
  mounted() {
    this.get_zhibo();
    this.getList(this.cid, this.id, this.name);
    this.getList1(this.cid1, this.id1, this.name1);
    this.getTab();
    this.handle_scrollTop();
  },
  methods: {
    // 恒银直播更多
    goMore() {
      this.$router.push({
        path: "/article_list",
        query: {
          column_id: '1469347553869857',
          id: '1469348485005345'
        },
      });
    },
    handle_zhibo(data) {
      if (data.hasLink) {
        window.open(data.link)
      } else {
        this.$router.push({
          path: "/details",
          query: {
            my_id: data.id,
            id: '1469348485005345',
          },
        });
      }
    },
    handle_scrollTop() {
      this.$("html,body").animate({ scrollTop: 0 }, 500); //回到顶端
    },
    handle_tab(data) {
      // this.$router.push({
      //   path: "/article_list",
      //   query: {
      //     column_id: data.columnId,
      //     id: data.id
      //   },
      // });
      this.getList(data.columnId, data.id, data.name);
    },
    handle_tab1(data) {
      this.getList1(data.columnId, data.id, data.name);
    },
    getTab() {
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/navigation/list_by_parent_id?parentId=1457026393702432`,
      }).then(res => {
        this.tabList1 = res.data
      })
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/navigation/list_by_parent_id?parentId=1469169155440673`,
      }).then(res => {
        this.tabList2 = res.data
      })
    },
    getList(cid, id, name) {
      this.cid = cid;
      this.id = id;
      this.name = name;
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        //url: `/api/web/cms/list_by_column_id?columnId=1457026263679008&start=0&limit=6`,
        url: `/api/web/cms/list_by_column_id?columnId=${cid}&start=0&limit=6`,
      }).then((response) => {
        let arr = [];
        response.data.rows.forEach((item) => {
          arr.push({
            ...item,
            lastModified: item.lastModified.split(" ")[0],
          });
        });
        this.list1 = arr;
      });
    },
    getList1(cid, id, name) {
      this.cid1 = cid;
      this.id1 = id;
      this.name1 = name;
      this.$axios({
        method: "get",
        headers: {
          'Authorization': this.cookies.get('token') ? this.cookies.get('token') : '',
        },
        url: `/api/web/cms/list_by_column_id?columnId=${cid}&start=0&limit=6`,
      }).then((response) => {
        let arr = [];
        response.data.rows.forEach((item) => {
          arr.push({
            ...item,
            lastModified: item.lastModified.split(" ")[0],
          });
        });
        this.list2 = arr;
      });
    },
    handle_(data, index) {
      if (data.hasLink) {
        window.open(data.link)
      } else {
        if (index === '1_') {
          this.$router.push({
            path: "/details",
            query: {
              my_id: data.id,
              //id: '1457026393702432'
              name: this.name
            },
          });
        } else if (index === '2_') {
          this.$router.push({
            path: "/details",
            query: {
              my_id: data.id,
              //id: '1460478414946336'
              name: this.name1
            },
          });
        }
      }
    },
    handle_more(data) {
      if (data === '研究报告') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: this.cid,
            //id: '1457026393702432',
            id: this.id,
            //name: '研究报告'
          },
        });
      } else if (data === '投资策略') {
        this.$router.push({
          path: "/article_list",
          query: {
            column_id: this.cid1,
            //id: '1469169721671714',
            id: this.id1,
            //name: '投资策略'
          },
        });
      }
    },
    handle_highlight(index) {
      this.idx = index
    },
    get_zhibo() {
      this.$axios({
        method: "get",
        url: `/api/web/cms/list_by_column_id?columnId=1469347553869857&start=0&limit=4`
      }).then(res => {
        this.list3 = res.data.rows
      })
    }
  }
}
</script>
<style scoped>
.data_center_compontent {
  border: 1px solid #f2f2f2;
  overflow: hidden;
}
.data_center_item {
  width: 130px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  border-radius: 1px;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
}
.highlight {
  background: #c21d14;
  color: #ffffff;
}
.data_center {
  width: 100%;
  height: 50px;
  background: #f2f2f2;
  display: flex;
  justify-content: flex-start;
  margin-top: 30px;
}
.insured_work_item > .thumb {
  width: 280px;
  height: 197px;
  overflow: hidden;
}
.insured_work_item > .thumb > img {
  width: 280px;
  height: auto;
}
.insured_work_item > p {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 45px;
  background: #000000;
  opacity: 0.45;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: #ffffff;
  text-align: center;
  line-height: 45px;
  padding: 0 15px;
}
.insured_work_item:hover {
  opacity: 0.8;
}
.insured_work_list {
  display: flex;
  flex-direction: row;
  padding-top: 30px;
  margin-bottom: 40px;
}
.insured_work_item {
  position: relative;
  cursor: pointer;
  margin-left: 27px;
}
.insured_work_item:nth-child(4n-3) {
  margin-left: 0px;
}
.second_level_study_top {
  display: flex;
  justify-content: space-between;
  margin-bottom: 40px;
}
.dian {
  width: 5px;
  height: 5px;
  background: #cacaca;
  border-radius: 50%;
  position: absolute;
  top: 25px;
  left: 20px;
}
.second_level_study_top_left_item {
  display: flex;
  justify-content: space-between;
  border-bottom: 1px #e5e5e5 solid;
}
.second_level_study_top_left_list {
  height: 300px;
}
.second_level_study_top_left_item > p,
.second_level_study_top_left_item > span {
  height: 51px;
  line-height: 51px;
}
.second_level_study_top_left_item > span {
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #999999;
  cursor: pointer;
}
.second_level_study_top_left_item > p {
  width: 70%;
  font-size: 16px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  overflow: hidden; /*超出部分隐藏*/
  white-space: nowrap; /*不换行*/
  text-overflow: ellipsis; /*超出部分文字以...显示*/
  cursor: pointer;
  position: relative;
  padding-left: 37px;
}
.second_level_study_top_left_item:hover.second_level_study_top_left_item > p {
  color: #c21d14;
}
.column_name_list {
  display: flex;
  padding: 0 20px;
  margin-top: 26px;
  flex-wrap: wrap;
  width: 100%;
  overflow: hidden;
}
.column_name_item:hover {
  background: #facecc;
  border: 1px solid #e08e89;
  color: #c21d14;
}
.column_name_item.current {
  background: #facecc;
  border: 1px solid #e08e89;
  color: #c21d14;
}
.column_name_item {
  margin-left: 13px;
  height: 35px;
  line-height: 35px;
  background: #f2f2f2;
  border: 1px solid #d2d2d2;
  border-radius: 18px;
  text-align: center;
  padding: 0 15px;
  cursor: pointer;
  margin-bottom: 7px;
}
.second_level_study {
  padding-top: 40px;
}
.second_level_study_top_left,
.second_level_study_top_right {
  width: 580px;
}
.second_level_study_top_title {
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  background: #f2f2f2;
  font-size: 20px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  display: flex;
  justify-content: space-between;
  border-top: #c21d14 2px solid;
}
.investor_protection_item {
  border-left: 4px #c21d14 solid;
  padding: 0 0 0 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.investor_protection_item span.more {
  cursor: pointer;
}
.investor_protection_title_left {
  font-size: 30px;
  font-family: Microsoft YaHei;
  font-weight: 400;
  color: #000000;
  line-height: 30px;
}
.ad {
  width: 100%;
}

.ad img {
  width: 100%;
  height: auto;
}
* {
  box-sizing: border-box;
}
</style>